import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController, PopoverController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  public loading: HTMLIonLoadingElement = undefined;
  constructor(
    private alertController: AlertController,
    private popverCtrl: PopoverController,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private toastController: ToastController
  ) { }

  async presentLoader(message = 'Cargando'){
    this.loading = await this.loadingController.create({
      message
    });
    await this.loading.present();
  }

  async presentAlert(subHeader, message){
    const alert = await this.alertController.create({
      subHeader,
      message,
      buttons: 
      [
        {
          text: 'Aceptar',
          role: 'accept',
          handler: () => {
            return true
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            return false
          }
        }
      ],
      backdropDismiss: false
    });
  
    await alert.present();
    const res = await alert.onDidDismiss();
    return res;
  }

  async createModal(component?, componentProps?, cssClass = 'currency', dropBack = false){
    // onEditCurrency
    const modal = await this.modalController.create({
      component,
      cssClass,
      componentProps,
      backdropDismiss: dropBack
    });
    await modal.present();
    const { data, role } = await modal.onDidDismiss();
    return { data, role }
  }

  async createPop(component?, componentProps?, cssClass?, event?, backdropDismiss?){
    const modal = await this.popverCtrl.create({
      component,
      cssClass,
      componentProps,
      event,
      backdropDismiss
    });
    await modal.present();
    const { data, role } = await modal.onDidDismiss();
    return { data, role }
  }

  async showToast(message){
    const toast = await this.toastController.create({
      message,
      duration: 3000
    });
    await toast.present();
  }

  
}
