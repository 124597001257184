import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/generales/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor( private storageService:StorageService, private navCtrl:NavController ){}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    
    const user = await this.storageService.get('userLogin');
    if(user){
      return (user.rol_id === 2) ? true : false;
    }
    this.navCtrl.navigateForward('/login')
    return false;
  }
  
}
