import { Component, HostListener } from '@angular/core';
import { IdiomaService } from './generales/services/idioma.service';
import { InitPushNotificationService } from './generales/services/init-push-notification.service';
import { PaisService } from './generales/services/pais.service';
import { Platform } from '@ionic/angular';
import { FirebaseAuthService } from './generales/login/services/firebase-auth.service';
import { environment } from 'src/environments/environment';
import { SharedService } from './directives/shared.service';
import { AdminServiceService } from './admin/service/admin-service.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent{
  @HostListener('window:beforeinstallprompt', ['$event'])
  async onbeforeinstallprompt(event:Event){
    this.deferredPrompt = event;
    this.saveDeferred = event;
  }

  @HostListener('window:appinstalled', ['$event'])
  async disableApp(event:Event){
    this.deferredPrompt = undefined;
    if(!this.platform.is('pwa')){
      this.deferredPrompt = this.saveDeferred
    }
  }

  installed:string;
  saveDeferred;
  deferredPrompt;
  url: string = environment.url;

  constructor(
    private idiomaService: IdiomaService,
    private paisService: PaisService,
    public initPush: InitPushNotificationService,
    private platform: Platform,
    private fbAuth: FirebaseAuthService,
    private sharedService: SharedService,
    private adminSv: AdminServiceService
  ) {
    this.adminSv.postStats('create');
    if(platform.is('capacitor')){
      this.initPush.startNotificationMobile('En component');
    }
    this.initServices();
    this.sharedService.subscribeData();
    if(!this.platform.is('android') && !this.platform.is('ios')){
      this.initPush.messageObservable.subscribe((payload: any) => {
        this.initPush.actionsPayload(payload);
      })
    }
  }

  async downloadPwa(){
    if (this.deferredPrompt) {
      this.deferredPrompt?.prompt();
      const { outcome } = await this.deferredPrompt.userChoice;
      if (outcome === 'accepted') {
      }
    }
  }

  async initServices() {
    await this.idiomaService.initLangage();
    await this.paisService.initCountry();
  }
}
