import { Injectable } from '@angular/core';

import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loading: any;

  constructor(
    public loadingCtrl: LoadingController,
    private translateSv: TranslateService
  ) {}

  async show(message?: string) {
    const msg = this.translateSv.instant('SENTENSES.POR_FAVOR_ESPERE');
    this.loading = await this.loadingCtrl.create({
      message: message ?? msg,
    });
    await this.loading.present();
  }

  async hide() {
    if(this.loading){
      await this.loading.dismiss();
    }
  }
}
